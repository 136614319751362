import {Box, Clickable, Hide, Image, Space, Text, useDevice, VStack} from 'platform/foundation';

import {isNotNil} from 'ramda-adjunct';

import {i18n, PriceReportType} from '@price-report/shared';

import {Nullish, parseDate, suffixTestId, TestIdProps} from 'shared';

import {VehicleImage} from '../../../components/VehicleImage/VehicleImage';
import {CatalogueType} from '../../../hooks/useCatalogue';
import {AvailableCarWithLogo} from '../types/available-car-with-logo';
import {openNewTab} from '../utils/openNewTab';
import {ColumnHeader} from './ColumnHeader';
import {List} from './List';

interface VehicleColumnProps extends TestIdProps {
  vehicle: AvailableCarWithLogo;
  vehiclePosition: number | Nullish;
  priceReport: PriceReportType;
  catalogue: CatalogueType;
  isMyVehicle: boolean;
  vehicleFeatures?: string[];
}

export function VehicleColumn(props: VehicleColumnProps) {
  const device = useDevice();
  const isMobile = device === 'mobile';

  const soldAtDate = isNotNil(props.vehicle.last_occurrence)
    ? parseDate(props.vehicle.last_occurrence)
    : undefined;

  const handleClick = () => {
    if (!props.vehicle.is_sold) {
      openNewTab(props.vehicle.url?.full);
    }
  };

  return (
    <Box
      backgroundColor={props.isMyVehicle ? 'palettes.teal.30.100' : undefined}
      width={38}
      paddingTop={2}
      borderRadius="small"
      boxShadow={props.isMyVehicle ? 'elevation_3' : undefined}
      position={props.isMyVehicle ? 'sticky' : undefined}
      left={props.isMyVehicle ? 35 : undefined}
      right={props.isMyVehicle ? 0 : undefined}
      zIndex={props.isMyVehicle ? 'SIDEBAR' : undefined}
    >
      <Box borderRight="1px solid" borderBottom="1px solid" borderColor="general.separator">
        <VStack>
          {props.vehicle.logoUrl ? (
            <Box>
              <VStack justify="center" align="center" height={24}>
                <Image
                  src={props.vehicle.logoUrl}
                  width="auto"
                  maxHeight={10}
                  data-testid={suffixTestId('vehicleLogo', props)}
                />
                <Space vertical={3} />
                <Text size="xSmall" color="tertiary" alternative>
                  {i18n.t('page.advertisements.yourVehicle')}
                </Text>
              </VStack>
            </Box>
          ) : (
            <Clickable
              onClick={handleClick}
              isDisabled={!!props.vehicle.is_sold || props.isMyVehicle}
            >
              <VehicleImage
                src={props.vehicle.thumbnail_url?.full}
                isMyVehicle={props.isMyVehicle}
                isSold={!!props.vehicle.is_sold}
                soldAt={soldAtDate}
                isSuspicious={!!props.vehicle.suspicious}
                maxHeight={24}
              />
            </Clickable>
          )}
          <Box padding={isMobile ? 2 : undefined}>
            <Hide onMobile>
              <ColumnHeader
                priceReport={props.priceReport}
                catalogue={props.catalogue}
                vehicle={props.vehicle}
                vehiclePosition={props.vehiclePosition}
                isMyVehicle={props.isMyVehicle}
                data-testid={suffixTestId('columnHeader', props)}
              />
            </Hide>
            <List
              firstWhite
              values={props.vehicleFeatures}
              compareWith={props.vehicle.features}
              isBold={true}
              isRow={false}
              spacing={2}
              textColor="primary"
              expandable
              sortable
              isMyVehicle={props.isMyVehicle}
              data-testid={suffixTestId('columnHeader', props)}
            />
          </Box>
        </VStack>
      </Box>
    </Box>
  );
}
